var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-filter-wrap"},[(_vm.$store.getters.getProductsReport.length > 0 && _vm.$store.getters.getProductsReportLoading !== true)?[_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'productsReport_ProductID',
              'productsReport_Name',
              'productsReport_Warehouse',
              'productsReport_Balance',
              'productsReport_Tracking',
              'productsReport_DateShip',
              'productsReport_Inbound',
              'productsReport_Outbound',
              'productsReport_InboundOutbound',
              'productsReport_CountChange',
              'productsReport_CountTotal',
              'productsReport_fba',
              'productsReport_fbm',
              'productsReport_inventory',
              'productsReport_Inbound',
              'productsReport_disposal',
              'productsReport_income',
              'productsReport_consolidation',
              ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_ProductID.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_Name.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_Warehouse.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_Balance.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_Tracking.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_DateShip.localization_value.value')))]),(_vm.navTabs.received.active)?_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_DateReceived.localization_value.value')))]):_vm._e(),_c('th',[(_vm.navTabs.received.active || _vm.navTabs.transit.active)?_c('span',[_vm._v("#"+_vm._s(_vm.$t('productsReport_Inbound.localization_value.value')))]):_vm._e(),_vm._v("  "),(_vm.navTabs.received.active)?_c('span',[_vm._v("#"+_vm._s(_vm.$t('productsReport_Outbound.localization_value.value')))]):_vm._e(),(_vm.navTabs.all.active || _vm.navTabs.damage.active)?_c('span',[_vm._v("#"+_vm._s(_vm.$t('productsReport_InboundOutbound.localization_value.value')))]):_vm._e()]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_CountChange.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productsReport_CountTotal.localization_value.value')))]),_c('th',{staticClass:"hide-1600",attrs:{"width":"100"}})])]),_c('tbody',[_vm._l((_vm.$store.getters.getProductsReport),function(item,index){return _vm._l((item.logs),function(product,indexProduct){return _c('tr',{key:index + 'n' + indexProduct,class:{
              'd-none': (_vm.navTabs.damage.active && !product.damage) || !_vm.navTabs.damage.active && product.damage ||
              !_vm.showWithFilterProduct(product),
            }},[_c('td',[_c('router-link',{staticClass:"table-link btn-style",attrs:{"to":_vm.$store.getters.GET_PATHS.products + '?id=' + product.product_id + `&type=${_vm.$route.query.storage ? _vm.$route.query.storage : 1}`,"target":'_blank'}},[_vm._v(" #"),_c('ValueHelper',{staticStyle:{"margin-left":"-3px"},attrs:{"value":product,"deep":'product_id'}})],1)],1),_c('td',[_c('div',{staticClass:"word-break white-space-normal",staticStyle:{"max-width":"450px"}},[_vm._v(_vm._s(product.product[0].name_for_fba))])]),_c('td',[_c('div',{staticClass:"site-status d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.getTypeName(item))+" ")])]),_c('td',[_c('ValueHelper',{attrs:{"value":product,"deep":'total_quantity_before'}})],1),_c('td',[_c('ValueHelper',{attrs:{"value":item,"deep":'order_model.0.object.tracking_number'}})],1),_c('td',[_c('ValueHelper',{attrs:{"value":item.shipped_at,"date":true,"dateType":'DD MMM, YYYY'}})],1),(_vm.navTabs.received.active)?_c('td',[_c('ValueHelper',{attrs:{"value":item.received_at,"date":true,"dateType":'DD MMM, YYYY'}})],1):_vm._e(),_c('td',[_c('router-link',{staticClass:"table-link btn-style",attrs:{"to":_vm.getTypeLink(item),"target":'_blank'}},[_c('ValueHelper',{attrs:{"value":item,"deep":'order_model.0.object.id'}})],1)],1),_c('td',[_c('ValueHelper',{attrs:{"value":product,"deep":'effected_quantity'}})],1),_c('td',[_c('ValueHelper',{attrs:{"value":product,"deep":'total_quantity_after'}})],1),_c('td',{staticClass:"hide-1600"})])})})],2)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},[_vm._l((_vm.$store.getters.getProductsReport),function(item,index){return _vm._l((item.logs),function(product,indexProduct){return _c('div',{key:index + 'n' + indexProduct,staticClass:"table-card__item",class:{'d-none': (_vm.navTabs.damage.active && !product.damage) || !_vm.navTabs.damage.active && product.damage ||
                      !_vm.showWithFilterProduct(product)}},[_c('ProductsReportTableMobile',{attrs:{"item":item,"product":product,"navTabs":_vm.navTabs,"filterGetParams":_vm.filterGetParams}})],1)})})],2)]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getProductsReportCommonList.next_page_url !== null && _vm.$store.getters.getProductsReport.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextProductsReportPage},attrs:{"count":_vm.$store.getters.getProductsReportCommonList.total - _vm.$store.getters.getProductsReportForPage < _vm.$store.getters.getProductsReportForPage ?
                      _vm.$store.getters.getProductsReportCommonList.total - _vm.$store.getters.getProductsReportForPage:
                      25},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])])]:_vm._e(),(_vm.$store.getters.getProductsReportLoading === false && _vm.$store.getters.getProductsReport.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }