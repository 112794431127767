<template>
  <ProductsReportTableUser
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"
      :navTabs="navTabs"
      @changeTab="changeTab"
      @filter="filter"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
  />
</template>

<script>
  import ProductsReportTableUser from "./ProductsReportTableUser/ProductsReportTableUser";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ProductsReportTable",
    components: {
      ProductsReportTableUser,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getProductsReportForPage,
        page: 1,
        onePage: 1,

        filterDate: [],
        filterType: '',

        filterOpenedDate: [],
        filterClosedDate: [],

        filterId: '',
        filterUserId: '',
        filterUserName: '',

        filterProductId: '',
        filterProductName: '',

        filterTypeReport: '',
        filterWarehouseType: '',
        filterStorage: '',
        // filterComplainId: '',
        //
        // filterStatus: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            active: true,
            label: this.$t('productsReport_all.localization_value.value'),
            name: 'all',
          },
          received: {
            active: false,
            label: this.$t('productsReport_received.localization_value.value'),
            name: 'received',
            value: 'inbound,consolidation_inbound',
          },
          shipped: {
            active: false,
            label: this.$t('productsReport_shipped.localization_value.value'),
            name: 'shipped',
            value: 'outbound',
          },
          transit: {
            active: false,
            label: this.$t('productsReport_transit.localization_value.value'),
            name: 'transit',
            value: 'inbound',
          },
          reserve: {
            active: false,
            label: this.$t('productsReport_reserve.localization_value.value'),
            name: 'reserve',
            value: 'reserve',
          },
          damage: {
            active: false,
            label: this.$t('productsReport_damage.localization_value.value'),
            name: 'damage',
            value: 'damage',
          },
        },

        createData: null,
      }
    },

    mounted() {
      if(this.$route.query.type && this._.has(this.navTabs, this.$route.query.type)){
        let tab = this.navTabs[this.$route.query.type]

        this.skipRadioChecked(this.navTabs, 'active')
        tab.active = true
      }

      if(this.filterDate.length === 0 && !this.$route.query.fromProdTable) {
        let monthAgoDate = new Date()
        monthAgoDate.setMonth(monthAgoDate.getMonth() - 1)

        // var d = new Date();
        // console.log(d);
        // d.setMonth(d.getMonth() - 3);
        // console.log(d);\
        this.getFilterParam()
        this.filterGetParams.date = this.$moment(monthAgoDate).format('MM/DD/YY') + ',' +this.$moment(new Date()).format('MM/DD/YY')
        this.changeFilter(this.filterGetParams)
        return
      }

      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          date: this.$route.query.date,
          type: this.$route.query.type,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,

          productId: this.$route.query.productId,
          productName: this.$route.query.productName,

          typeReport: this.$route.query.typeReport,

          warehouseType: this.$route.query.warehouseType,

          storage: this.$route.query.storage,

          // complaintId: this.$route.query.complaintId,
          //
          // status: this.$route.query.status,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextProductsReportPage', true)

        // save filter params in store
        this.$store.commit('setProductsReportFilter', window.location.search)


        if(this.navTabs.all.active || this.navTabs.received.active || this.navTabs.shipped.active || this.navTabs.damage.active){
          this.$store.dispatch('fetchProductsReport', url).then(() => {
            // skip get next page
            this.$store.commit('setNextProductsReportPage', false)
          })
        }

        if(this.navTabs.transit.active){
          this.$store.dispatch('fetchProductsReportTransit', url).then(() => {
            this.$store.commit('setNextProductsReportPage', false)
          })
        }

        if(this.navTabs.reserve.active){
          this.$store.dispatch('fetchProductsReportReserve', url).then(() => {
            // skip get next page
            this.$store.commit('setNextProductsReportPage', false)
          })
        }

        if(this.isAdmin){
          this.checkCountFilter(['type', 'userName', 'productName'])
        } else {
          this.checkCountFilter(['type', 'userName', 'userId', 'productName'])
        }
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        if(this.navTabs.all.active || this.navTabs.received.active || this.navTabs.shipped.active) {
          this.generateFilterQueryParams(
              myQuery,
              {
                filterId: 'id',
                filterUserId: 'ProductUserId',
                filterProductId: 'product_id',
                filterWarehouseType: 'operation_type',
              },
          )

          if (this.filterType !== '' && this.filterType !== 'all')
            myQuery.where('operation_type', this.navTabs[this.filterType].value)
        }

        if(this.navTabs.reserve.active) {
          this.generateFilterQueryParams(
              myQuery,
              {
                // filterId: 'id',
                filterUserId: 'user_id',
                filterProductId: 'product_id',
                // filterWarehouseType: 'operation_type',
              },
          )
        }

        if(this.navTabs.transit.active) {
          this.generateFilterQueryParams(
              myQuery,
              {
                // filterId: 'id',
                filterUserId: 'user_id',
                filterProductId: 'product_id',
                // filterWarehouseType: 'operation_type',
              },
          )
        }

        if(this.navTabs.damage.active) {
          myQuery.where('damage', 1)
          this.generateFilterQueryParams(
              myQuery,
              {
                filterId: 'id',
                filterUserId: 'ProductUserId',
                filterProductId: 'product_id',
                filterWarehouseType: 'operation_type',
              },
          )
        }

        this.generateFilterQueryParams(
            myQuery,
            {
              filterStorage: 'byStorageId',
            },
        )

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'shippedAt',
              date
          )
        }


        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },
    },

  }
</script>

<style scoped>

</style>